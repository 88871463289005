import React, { useState } from "react"
import Image from "../image-component/image-2023"
import { Formik, Form, Field, ErrorMessage } from "formik"

export default function HomepageForm() {
  const [tyVisible, setVisible] = useState(false)
  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  return (
    <section className="get-predictions">
      <div className="container">
        <div className="form-wrap">
          <div className="form-heading">
            Get the top 3 predictions for your site
          </div>
          <div className="form-subheading">
            See the top 3 recommendations from our prediction engine for your
            most important page when you book a demo.
          </div>
          <div className="the-form">
            <Formik
              initialValues={{ name: "", email: "" }}
              validate={values => {
                const errors = {}
                const websiteRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
                if (!values.name) {
                  errors.name = "Please enter your name"
                }
                if (!values.email) {
                  errors.email = "Please enter your email address"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "The email you entered doesn't seem right"
                } else if (
                  [
                    "gmail",
                    "icloud",
                    "yahoo",
                    "outlook",
                    "icloud",
                    "aol",
                    "zoho",
                    "mail",
                    "protonmail",
                    "yandex",
                    "yopmail",
                  ].indexOf(values.email.split("@")[1].split(".")[0]) > -1
                ) {
                  errors.email = "Please enter your business email"
                }
                return errors
              }}
              onSubmit={values => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({
                    "form-name": "full-service-team-form",
                    ...values,
                  }),
                })
                  // On success, redirect to the custom success page using Gatsby's `navigate` helper function
                  .then(() => {
                    setTimeout(() => {
                      setVisible(!tyVisible)
                      // window.lintrk('track', { conversion_id: 6913844})
                      document.querySelector(".form-wrap").style.display =
                        "none"
                      document.querySelector(
                        ".thank-you-message"
                      ).style.display = "block"
                    }, 400)
                  })
                  .catch(error => alert(error))
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form
                  autoComplete="off"
                  className="form"
                  method="post"
                  name="full-service-team-form"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <Field type="hidden" name="bot-field" />
                  <Field
                    type="hidden"
                    name="form-name"
                    value="full-service-team-form"
                  />
                  <div className="input-wrap">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={
                        errors.name && touched.name
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <ErrorMessage
                      className="input-error"
                      name="name"
                      component="span"
                    />
                  </div>
                  <div className="input-wrap">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={
                        errors.email && touched.email
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <ErrorMessage
                      className="input-error"
                      name="email"
                      component="span"
                    />
                  </div>
                  <button
                    type="submit"
                    className="submit-btn arrow-button"
                    disabled={isSubmitting}
                  >
                    Get a Demo -->
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className="thank-you-message"
          style={{ display: tyVisible ? "block" : "none" }}
        >
          <div className="ty-message">
            <Image
              sourceFolder={"get-demo"}
              imgName={"thank-you-image.png"}
              className={"ty-icon"}
              alt={"TY icon"}
            />
            <div className="ty-heading">Thank you for getting in touch!</div>
            <div className="ty-subheading">
              We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon!
            </div>
            <div className="ty-subheading">
              For immediate assistance please call us at{" "}
              <span>888-677-4725</span>
            </div>
            {/* <Link to="/" className="cta-big-filled">Back to main page</Link> */}
          </div>
        </div>
      </div>
    </section>
  )
}

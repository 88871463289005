import React, { lazy, Suspense, useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./team-form"
import FallbackLoading from "../loader/loader";
import "./team.scss"
const SliderSection = lazy(() => import('./slider'))

export default function FullServiceTeam(props) {
  const isSSR = typeof window === "undefined"
  const [isMobile, setMobile] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth > 991) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  },)


  return (
    <span className={props.wrapperClass}>
      <section className={"hero-section"}>
        <SiteNavigation />
        <div className="hero-content-wrap">
          <div className="hero-left">
            <div className="hero-superheading"><Link to="/how-it-works">How it Works</Link> <span>> Full Service Team</span></div>
            <div className="hero-heading">Our CRO team handles everything for you.<br className="desktop-only"/> From design to dev.</div>
            <div className="hero-subheading">
              <p>Get more tests running with a dedicated team of 160 CRO Experts to handle all the details for you.  So you get more tests live without tying up internal resources.</p>
            </div>
            <div className="page-anchors"><Link to="/full-service-team#research">Research</Link> · <Link to="/full-service-team#copy">{isMobile ? 'Copy' : 'Copywriting'}</Link> · <Link to="/full-service-team#design">Design</Link> · <Link to="/full-service-team#dev">{isMobile ? 'Dev' : 'Development'}</Link> · <Link to="/full-service-team#analytics">Analytics</Link> · <Link to="/full-service-team#pm">{isMobile ? 'PM' : 'Project Management'}</Link></div>
            <Link className="arrow-button" to="/get-demo/">Get a Demo --></Link>
          </div>
          <div className="hero-right">
            <Image
              sourceFolder={'homepage'}
              lazyLoad={"true"}
              alt={"Full service team"}
              fallBackImage={'2023-full-service-desktop.webp'}
              imgName={[
                {
                  imageName: "2023-full-service-desktop.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "2023-full-service-tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "2023-full-service-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
      </section>
      <section className="social-proof-section">
        <Image
          sourceFolder={'homepage'}
          lazyLoad={"true"}
          alt={"Social proof"}
          fallBackImage={'2023-social-logos-mobile.webp'}
          imgName={[
            {
              imageName: "2023-social-logos-desktop.webp",
              minBreakpoint: "992px",
            },
            {
              imageName: "2023-social-logos-tablet.webp",
              minBreakpoint: "768px",
            },
            {
              imageName: "2023-social-logos-mobile.webp",
              minBreakpoint: "320px",
            },
          ]}
        />
      </section>
      <section className="how-it-works">
        <div className="row-wrap" id="research">
          <div className="row-left">
            <Image
              sourceFolder={'full-team'}
              lazyLoad={"true"}
              alt={"research"}
              fallBackImage={'research.webp'}
              imgName={[
                {
                  imageName: "research.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "research-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="image-caption">Custom research techniques reveal what is stopping visitors from converting. Includes interviews, analytics, heatmapping, field research, and more.</div>
          </div>
          <div className="row-right">
            <div className="row-super-heading">Research</div>
            <div className="row-heading"><span>Discover what is</span> keeping visitors from converting.</div>
            <div className="row-text">
              <p>Our research team uncovers the motivation gaps, objections, and ambiguities blocking conversions on your site. Methods include <span>interviews,</span> <span>analytics,</span> <span>session recording,</span> <span>heatmapping,</span> <span>exit polling,</span> <span>review mining,</span> <span>field research,</span> <span>message testing,</span> <span>competitor analysis,</span> and <span>more.</span></p>
              <p>Custom research adds a layer of qualitative data to the macro insights from our <Link to="/prediction-engine/">prediction engine.</Link></p>
            </div>
            <Link className="arrow-button-outline">Learn more  --></Link>
          </div>
        </div>
        <div className="row-wrap reverse" id="copy">
          <div className="row-left">
            <div className="row-super-heading">Copywriting & WIREFRAMING</div>
            <div className="row-heading"><span>High converting copy in your voice.</span> Written by industry specialists.</div>
            <div className="row-text">
              <p>Our copywriters are industry specialist, bringing understanding of your industry, buyer personas, and what works.  Digital security, FinTech, HR, DevOps, MarTech, and 17 other SaaS verticals.</p>
              <p>Instead of enduring hours explaining SOC 2 to a newly graduated art history major - get high converting copy that sails through product, brand, and legal review.</p>
            </div>
            <Link className="arrow-button-outline">Learn more  --></Link>
          </div>
          <div className="row-right">
            <Image
              sourceFolder={'full-team'}
              lazyLoad={"true"}
              alt={"wireframing"}
              fallBackImage={'wireframing.webp'}
              imgName={[
                {
                  imageName: "wireframing.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "wireframing-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="image-caption">A copywriting team that brings industry expertise.  Gets you high converting copy, fast.</div>
          </div>
        </div>
        <div className="row-wrap" id="design">
          <div className="row-left">
            <Image
              sourceFolder={'full-team'}
              lazyLoad={"true"}
              alt={"design"}
              fallBackImage={'design.webp'}
              imgName={[
                {
                  imageName: "design.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "design-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
          <div className="row-right">
            <div className="row-super-heading">Design</div>
            <div className="row-heading"><span>Discover what is</span> keeping visitors from converting.</div>
            <div className="row-text">
              <p>Our designers act like an extension of your team. We create pages that are both on-brand and conversion-focused.  We work across SaaS styles from minimalist to glassmorphic.</p>
              <p>Get designs that get your brand and design teams excited about testing.</p>
            </div>
            <Link className="arrow-button-outline">Learn more  --></Link>
          </div>
        </div>
        <div className="row-wrap reverse" id="dev">
          <div className="row-left">
            <div className="row-super-heading">Development & QA</div>
            <div className="row-heading"><span>Go from idea to fully</span> built-out test in a week.</div>
            <div className="row-text">
              <p>Fast development by a team of 40 experienced A/B testing software engineers. We develop across A/B testing platforms (Optimizely, VWO, Convert, Mutiny, etc). We can also develop directly in your CMS (WordPress, Marketo, HubSpot, Unbounce, Contentful, and more). Dedicated QA so it is pixel perfect every time. We even hard-code your winners.</p>
            </div>
            <Link className="arrow-button-outline">Learn more  --></Link>
          </div>
          <div className="row-right">
            <Image
              sourceFolder={'full-team'}
              lazyLoad={"true"}
              alt={"devepolment & qa"}
              fallBackImage={'dev.webp'}
              imgName={[
                {
                  imageName: "dev.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "dev-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="image-caption">Your dedicated dev & QA team take tests from idea to live in two weeks with a dedicated dev & QA team.</div>
          </div>
        </div>
        <div className="row-wrap" id="analytics">
          <div className="row-left">
            <Image
              sourceFolder={'full-team'}
              lazyLoad={"true"}
              alt={"analytics"}
              fallBackImage={'analytics.webp'}
              imgName={[
                {
                  imageName: "analytics.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "analytics-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
          <div className="row-right">
            <div className="row-super-heading">Analytics</div>
            <div className="row-heading"><span>Measure what matters</span> with precision.</div>
            <div className="row-text">
              <p>Build and validate test instrumentation so you can measure all the most important points along your conversion funnel. MQL, SQL, closed deal, free-to-paid, upsells, AOV, and more.</p>
              <p>Reconcile data from google analytics with your backend systems, so you have a single source of truth.</p>
            </div>
            <Link className="arrow-button-outline">Learn more  --></Link>
          </div>
        </div>
        <div className="row-wrap reverse" id="pm">
          <div className="row-left">
            <div className="row-super-heading">Project Management</div>
            <div className="row-heading"><span>Everything on track</span> and organized for you.</div>
            <div className="row-text">
              <p>Your project manager is your central point of contact. They maintain the strategic roadmap, plan ahead, and unblock so you are running the right tests and lots of them. Your project manager coordinates communication between teams and documents test plans, learnings, and results.</p>
            </div>
            <Link className="arrow-button-outline">Learn more  --></Link>
          </div>
          <div className="row-right">
            <Image
              sourceFolder={'full-team'}
              lazyLoad={"true"}
              alt={"devepolment & qa"}
              fallBackImage={'project-management.webp'}
              imgName={[
                {
                  imageName: "project-management.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "project-management-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="image-caption">Your project manager takes primary responsibility for getting your results.  They maintain the strategic roadmap, are your primary point of contact, and focus on expediting the project.</div>
          </div>
        </div>
      </section>
      <FormSection />
      {
        !isSSR && (
          <Suspense fallback={<FallbackLoading />}>
            <SliderSection />
          </Suspense>
        )
      }
    </span >
  )
}
